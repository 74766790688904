import React, { useState, useEffect } from "react";
import moment from "moment";

const Countdown = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const now = moment();
        const target = moment(targetDate);
        const difference = target.diff(now);

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);

        return { days, hours, minutes };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [targetDate]);

    if (!targetDate) {
        return <div>No target date provided.</div>;
    }

    return (
        <div className="time-txt">
            <span className="day-name">
                <span className="time-num">{timeLeft.days}</span> dag -&nbsp;
            </span>
            <span className="day-name">
                <span className="time-num">{timeLeft.hours}</span> uren -&nbsp;
            </span>
            <span className="day-name">
                <span className="time-num">{timeLeft.minutes}</span> minuten
            </span>
        </div>
    );
};

export default React.memo(Countdown);
