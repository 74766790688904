import {
    AdvancedMarker,
    InfoWindow,
    Map,
    Marker,
    MarkerProps,
    Pin,
    useAdvancedMarkerRef,
    useMap
} from "@vis.gl/react-google-maps";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {Product} from "../redux/models/Product";

interface MapDelegate {
    onDragEnd: (product: Product | undefined | null) => void
    onDragStart:  (product: Product | undefined | null) => void
}
type MarkerProps = ProductWrapper
export const MapMarker = (props: MarkerProps) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [infoWindowShown, setInfoWindowShown] = useState(false);
    const handleClose = useCallback(() => setInfoWindowShown(false), []);
    return (
        <>
            <AdvancedMarker
                key={props.product.id}
                position={{ lat: props.selectedLocation.lat, lng: props.selectedLocation.lng  }}
                ref={markerRef}
            >
                <Pin  background={'#FBBC04'} glyphColor={'#fff'} borderColor={'#fff'} />
            </AdvancedMarker>
            <div className="product-pin-img">
                <InfoWindow anchor={marker}>
                    <img src={props.product.image} alt="" className="img_center_large"/>
                </InfoWindow>
            </div>
        </>
    )
}

interface Location {lat: number, lng: number}
export class ProductWrapper {
    product: Product;
    selectedLocation: Location;
    constructor(product: Product, selectedLocation: Location) {
        this.product = product;
        this.selectedLocation = selectedLocation;
    }
}

export const MapComponent = forwardRef((props: MapDelegate, fRef) => {
    const [center, setCenter] = useState<Location | null>(null);
    const [products, setProducts] = useState<ProductWrapper[]>([]);
    const map = useMap();
    const [currentMarkerRef, currentMarker] = useAdvancedMarkerRef();
    // const currentMarkerProduct = useState<Product>(null)
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

    useImperativeHandle(fRef, () => ({
        addMarker(product: Product) {
            setSelectedProduct(product)
        },
        dropMarker() {
            const _selectedProduct = {...selectedProduct}
            const _center = {...center}
            setProducts(prevState => {
                return [...prevState, {
                    product: _selectedProduct,
                    selectedLocation: _center
                }]
            })
            setSelectedProduct(null)
        },
        setMapCenter(lat: number, lng: number) {

            if (!center){
                console.info("setMapCenter")
                setCenter({ lat, lng });
            }
        },
        getSelectedProduct(): ProductWrapper | null {
            if (selectedProduct){
                return {
                    product: selectedProduct,
                    selectedLocation: center
                } as ProductWrapper
            }else {
                return null
            }

        },
        getLatestCoordinates() {
            return center;
        }
    }), [map, selectedProduct, center]);

    const handleCenterChange = useCallback(() => {
        const newCenter = map.getCenter();
        setCenter(prevState => {
            return { lat: newCenter.lat(), lng: newCenter.lng() }
        });
        console.info("handleCenterChange")
    }, [map]);


  const handleDragEnd = useCallback(() => {
      const newCenter = map.getCenter();
      const latestCoordinates = { lat: newCenter.lat(), lng: newCenter.lng() };
      setCenter(prevState => {
          return latestCoordinates
      });
      props.onDragEnd(selectedProduct)
      console.info("handleDragEnd")
  }, [map, selectedProduct]);
    const handleDragStart =  useCallback(() => {
        props.onDragStart(selectedProduct)
        console.info("handleDragStart")
    }, [map, selectedProduct]);

    useEffect(() => {
        if (map) {
            map.addListener('center_changed', handleCenterChange);
            map.addListener('dragend', handleDragEnd);
            map.addListener('dragstart', handleDragStart);

            return () => {
                console.info(`Destroy map ${map}, selected: ${selectedProduct}`);
                try {
                    google.maps.event?.removeListener(map, 'center_changed', handleCenterChange);
                    google.maps.event?.removeListener(map, 'dragend', handleDragEnd);
                    google.maps.event?.removeListener(map, 'dragstart', handleDragStart);
                }catch (e){
                    console.info({ map })
                    console.error(e);
                }
            };
        }
    }, [map, selectedProduct]);

    return (
        <>
            {
               center && <Map
                    mapId='BESTEMMING_JMB'
                    style={{ width: '100vw', height: '100dvh' }}
                    defaultCenter={center}
                    defaultZoom={14}
                    // minZoom={13}
                    maxZoom={10}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                >
                    {
                        products?.map(p => <MapMarker key={p.product.id} product={p.product} selectedLocation={p.selectedLocation} />)
                    }
                    {
                        selectedProduct && <MapMarker key={selectedProduct.id} product={selectedProduct} selectedLocation={{lat: center.lat, lng: center.lng}} />
                    }
                </Map>
            }
        </>
    );
});
