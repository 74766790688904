import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  RawAxiosRequestConfig, RawAxiosRequestHeaders
} from 'axios'
import {Shop} from "../redux/models/Shop";
import {APIResponse} from "../redux/models/APIResponse";
import {Product} from "../redux/models/Product";
import {User} from "../redux/models/User";
import {ProductResponse} from "../redux/models/ProductResponse";


export type ShopDataType = Array<Shop>
export type ShopResponseType = APIResponse<ShopDataType>


export type ProductsResponseType = APIResponse<ProductResponse>

export type UserDataType = User
export type UserResponseType = APIResponse<UserDataType>
// axios.defaults.withCredentials = true;
export type StartGameType = { score_id?: number }
export type StartGameResponseType =  APIResponse<StartGameType>

export type EndGameType = { score_id?: number, distance?: number }
export type EndGameResponseType =  APIResponse<EndGameType>

function CreateClient(
  baseURL?: string,
  headers?: RawAxiosRequestHeaders,
  _getHeaders?: any,
): AxiosInstance {
  const config = { baseURL: baseURL, headers: headers } as CreateAxiosDefaults;
  const axiosClient = axios.create(config)
  axiosClient.interceptors.response.use(function (response) {
    return response
  })
  return axiosClient
}

const API_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Content-Transfer-Encoding': 'base64',
}

export class APIClient {
  private static instance: APIClient

  private apiClient: AxiosInstance

  private constructor(client: AxiosInstance) {
    this.apiClient = client
  }

  private static getBaseURL(): string {
    // @ts-ignore
      return  `${import.meta.env.VITE_APP_API_URL}`
  }

  public static init(){
    const HEADER = {...API_HEADER} as RawAxiosRequestHeaders
    this.instance = new APIClient(
        CreateClient(import.meta.env.VITE_APP_API_URL, HEADER, async (headers: any) => headers)
    )
  }

  private get<T>(path: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
    return this.apiClient.get<T>(path, config)
  }
  private post<T>(
    path: string,
    data: any,
    config?: AxiosRequestConfig | undefined,
  ): Promise<AxiosResponse<any>> {
    return this.apiClient.post<T>(path, data, config)
  }
  private put<T>(
    path: string,
    data: any,
    config?: RawAxiosRequestConfig,
  ): Promise<AxiosResponse<any>> {
    return this.apiClient.put<T>(path, data, config)
  }
  public delete(path: string, config?: RawAxiosRequestConfig): Promise<AxiosResponse<any>> {
    return this.apiClient.delete(path, config)
  }


  public static GetShops(): Promise<ShopResponseType> {
      return APIClient.instance
        .get<ShopDataType>('/shops')
          // @ts-ignore
        .then((r) => Promise.resolve(r.data))
  }

    public static GetProducts(user:User): Promise<ProductsResponseType> {
        return APIClient.instance
            .get<ProductResponse>(`/products?user_id=${user.id}`)
            // @ts-ignore
            .then((r) => Promise.resolve(r.data))
    }

    public static Register(user:User): Promise<UserResponseType> {
        return APIClient.instance
            .post<ProductResponse>(`/register`, user)
            // @ts-ignore
            .then((r) => Promise.resolve(r.data))
    }

    public static StartGame(data: {user_id: string, product_id: string}): Promise<StartGameResponseType> {
        return APIClient.instance
            .post<StartGameType>(`/start-game`, data)
            // @ts-ignore
            .then((r) => Promise.resolve(r.data))
    }

    public static EndGame( data: {score_id: number, longitude: number, latitude: number}): Promise<EndGameResponseType> {
        return APIClient.instance
            .post<EndGameType>(`/end-game`, data)
            // @ts-ignore
            .then((r) => Promise.resolve(r.data))
    }

    public static GetScore(user:User): Promise<UserResponseType> {
        return APIClient.instance
            .get<ProductDataType>(`/my-score?user_id=${user.id}`)
            // @ts-ignore
            .then((r) => Promise.resolve(r.data))
    }
}



