import PageRouter from './pages/PageRouter'
import {APIProvider} from "@vis.gl/react-google-maps";
// import {Toaster} from "react-hot-toast";

function App() {
    return (
        <>
        {/* <div><Toaster/></div> */}
            <APIProvider apiKey={import.meta.env.VITE_APP_MAP_KEY}>
                <PageRouter/>
            </APIProvider>
        </>
    )

}

export default App
