import MainButton from './MainButton';
import React, { useState } from 'react';
import AlreadyPlayed from './AlreadyPlayed';

const CorrectAnswer = ({ scoreData, nextWeek }) => {
    const [alreadyPlayed, setAlreadyPlayed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // console.log(scoreData);

    const handleAlreadyPlayed = () => {
        setIsLoading(true); // Start loading
        setTimeout(() => {
             setAlreadyPlayed(true);
             setIsLoading(false);
        }, 1000)
    };

    if (alreadyPlayed) {
        return <AlreadyPlayed nextWeek={nextWeek}  />;
    }

    return (
        <>
            <div className="mobile_cont_section">
                <h1 className="main_top_hd correct-titel">JUISTE LOCATIES</h1>
                <div className="middle_contant_main">
                    <div className="midd_cont_inn">
                        <div className="corr_km_list">
                            <ul>
                            {scoreData && Array.isArray(scoreData.score) && scoreData.score.length > 0 ? (
                                scoreData.score.map((item, index) => (
                                    <li key={index}>
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="product_lft_cont">
                                                    <div className="product_lft_div_img">
                                                        {item.product_image ? (
                                                            <img className="img-fluid" src={item.product_image} alt="Product" />
                                                        ) : (
                                                            <p>No Image Available</p>
                                                        )}
                                                    </div>
                                                    <div className="product_lft_div_name">
                                                        {item.product_name || "Unknown Product"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-9 p-0">
                                                <div className="product_right_cont">
                                                    <div className="pro_hd_km_bg">
                                                        <div className="pro_hd">{item.location || "Unknown Location"}</div>
                                                        <div className={`pro_km_dist ${item.is_correct_drop === 1 ? 'green_txt' : ''}`}>
                                                            {item.is_correct_drop === 1 ? "Correct" : "(" + item.distance + ")" || "Unknown Distance"}
                                                        </div>
                                                    </div>
                                                    <div className="pro_desp_txt">{item.product_description || "No Description Available"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <li>No correct answers available.</li>
                            )}
                            </ul>
                            <MainButton onClick={handleAlreadyPlayed} isLoading={isLoading}>SLUITEN</MainButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CorrectAnswer;
