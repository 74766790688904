import MainButton from "../components/MainButton";
import BottomContent from "../components/BottomContent";
import { ROUTE_MAP_PAGE } from "../helper/Contstant";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function RulesPage() {
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleNavigation = () => {
        setIsLoading(true); // Start loading
        setTimeout(() => {
             navigate(ROUTE_MAP_PAGE); // Navigate to the registration page
             setIsLoading(false);
         }, 1000);
    };

    return (
        <>
            <section className="registration-page">
                <div className="container mobile_container">
                    <div className="intro-inner-sec inner-mobile-container rules-cont">
                        <div className="blank_div"></div>
                        <div className="middle_contant_main  mobile_cont_section">
                            <h1 className="main_top_hd">SPELREGELS</h1>
                            <div className="midd_cont_inn">
                                <div className="rules_list">
                                    <ul>
                                        <li className="left_arr">
                                            <img
                                                src="/assets/images/Arrow_right_yellow.svg"
                                                alt=""
                                                className="img-fluid rules-arrow-img"
                                            />
                                            <p className="rules-txt">
                                                Selecteer je eerste product en
                                                verplaats de map en bevestig.
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/Arrow_right_yellow.svg"
                                                alt=""
                                                className="img-fluid rules-arrow-img"
                                            />
                                            <p className="rules-txt">
                                                Doe hetzelfde met alle andere
                                                producten en bevestig
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/Arrow_right_yellow.svg"
                                                alt=""
                                                className="img-fluid rules-arrow-img"
                                            />
                                            <p className="rules-txt">
                                                Elke week 5 nieuwe willekeurige
                                                producten
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/Arrow_right_yellow.svg"
                                                alt=""
                                                className="img-fluid rules-arrow-img"
                                            />
                                            <p className="rules-txt">
                                                Zolang je niet bevestigt kan je
                                                de producten nog verplaatsen
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/Arrow_right_yellow.svg"
                                                alt=""
                                                className="img-fluid rules-arrow-img"
                                            />
                                            <p className="rules-txt">
                                                Hoe sneller je bent, hoe hoger
                                                je score.
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/Arrow_right_yellow.svg"
                                                alt=""
                                                className="img-fluid rules-arrow-img"
                                            />
                                            <p className="rules-txt">
                                                Heb je gewonnen dan word je via
                                                mail op de hoogte gebracht
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                src="/assets/images/Arrow_right_yellow.svg"
                                                alt=""
                                                className="img-fluid rules-arrow-img"
                                            />
                                            <p className="rules-txt">
                                                Speel elke week opnieuw mee en
                                                maak elke keer kans om te winnen
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                        <MainButton onClick={handleNavigation} isLoading={isLoading}>
                                START HET SPEL
                            </MainButton>
                        <BottomContent />
                    </div>
                </div>
            </section>
        </>
    );
}
