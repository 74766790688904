

export class LocalStorage {
    static getUser(){
        const data = localStorage.getItem("registration_data");
        if (data) {
            const registration = JSON.parse(data) ;
            return registration;
        }
        return undefined;
    }
}
