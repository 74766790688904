import BottomContent from '../components/BottomContent'
import SplashPage from '../components/SplashPage'

export default function IntroPage() {
    return (
        <>
            <section className="registration-page">
                <div className="container mobile_container">
                    <div className="intro-inner-sec inner-mobile-container">
                        <SplashPage />
                        {/* <CorrectAnswer /> */}
                        {/* <Score /> */}
                        {/* <AlredyPlay /> */}
                        {/* <MapPage /> */}
                        {/* <RulesPage /> */}
                        <BottomContent />
                    </div>
                </div>
            </section>
        </>
    )
}
