import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from "../models/User";
import {GuestCampClient, KeyValueContainer, MailExist} from "@snayvik-tech-labs/guestcamp-api"
import {Shop} from "../models/Shop";
import {
    APIClient,
    ProductDataType,
    ShopDataType,
    ShopResponseType,
    UserDataType,
    UserResponseType
} from "../../api/APIClient";

export interface AppState {
    shops?: ShopDataType | null,
    isShopLoading: boolean,

    products?: ProductDataType | null,
    isProductLoading: boolean,

    user: null,
    isRegisterLoading: false,

    error?: Error | null;
}

const initialState: AppState = {
    shops: null,
    isShopLoading: false,

    products: null,
    isProductLoading: false,

    user: null,
    isRegisterLoading: false,

    error: null,
};

export const fetchShops = createAsyncThunk<ShopDataType, null, { rejectValue: Error }>('shops', async () => {
    const res = await APIClient.GetShops();
    return res.data;
});

export const fetchProducts = createAsyncThunk<ProductDataType, User , { rejectValue: Error }>('products', async (args) => {
    const res = await APIClient.GetProducts(args);
    return res.data;
});


export const registerUser = createAsyncThunk<UserDataType, User , { rejectValue: Error }>('register', async (args) => {
    const res = await APIClient.Register(args);
    return res.data;
});


// @ts-ignore
const appSlice = createSlice<AppState>({
    name: 'email-check',
    initialState,
    reducers: {
        nop: (state) => state,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchShops.pending, (state) => {
                state.isShopLoading = true;
                state.error = null;
            })
            .addCase(fetchShops.fulfilled, (state, action: PayloadAction<ShopDataType>) => {
                state.isShopLoading = false;
                state.user = action.payload;
            })
            .addCase(fetchShops.rejected, (state, action) => {
                state.isShopLoading = false;
                state.error = action.error?.message || 'Failed to fetch email check';
            });

        builder
            .addCase(fetchProducts.pending, (state) => {
                state.isProductsLoading = true;
                state.error = null;
            })
            .addCase(fetchProducts.fulfilled, (state, action: PayloadAction<ProductDataType>) => {
                state.isProductsLoading = false;
                state.products = action.payload;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.isProductsLoading = false;
                state.error = action.error?.message || 'Failed to fetch email check';
            });


        builder
            .addCase(registerUser.pending, (state) => {
                state.isRegisterLoading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action: PayloadAction<UserDataType>) => {
                state.isRegisterLoading = false;
                state.user = action.payload;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.isRegisterLoading = false;
                state.error = action.error?.message || 'Failed to fetch email check';
            });



    },
});

export default appSlice.reducer;
