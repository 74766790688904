import React, { useEffect, useState } from 'react';
import { APIClient } from '.././api/APIClient.js';
import { Shop } from '../redux/models/Shop';
import BottomContent from '../components/BottomContent';
import { useNavigate } from 'react-router-dom';
import {
    FIELD_NAME_EMAIL,
    FIELD_NAME_FIRST_NAME,
    FIELD_NAME_LAST_NAME,
    FIELD_NAME_PRIVACY_POLICY,
    FIELD_TYPE_CHECKBOX,
    ROUTE_RULES_PAGE
} from '../helper/Contstant.js';
import { Dictionary } from '../redux/models/APIResponse';
import MainButton from '../components/MainButton';
import { User } from '../redux/models/User';

const RegistrationPage = () => {
    const navigate = useNavigate();
    const [fieldContainer, setFieldContainer] = useState<Dictionary<string | null | number | boolean>>({});
    const [response, setResponse] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [shops, setShops] = useState<Shop[]>([]);
    const [fieldValidity, setFieldValidity] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const validRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    useEffect(() => {
        const fetchShops = async () => {
            try{
                const shopData = await APIClient.GetShops();
                    setShops(shopData.data);
            } catch (err) {
                console.error('Failed to fetch shops:', err);
                setError('Failed to fetch shops');
            }
        };
        fetchShops();
    }, []);

    const onChange = (e) => {
        const { name, type, value, checked } = e.target;
        setFieldContainer(prev => ({ ...prev, [name]: type === FIELD_TYPE_CHECKBOX ? checked : value }));
        validateField(e);
    };

    const validateField = (e) => {
        const { name, value } = e.target;
        const isValid = value.trim() !== '' && (name !== FIELD_NAME_EMAIL || validRegex.test(value));
        setFieldValidity(prev => ({ ...prev, [name]: isValid }));
    };

    const isFormValid = () => {
        return Object.values(fieldValidity).every(Boolean) &&
               fieldContainer[FIELD_NAME_FIRST_NAME] &&
               fieldContainer[FIELD_NAME_LAST_NAME] &&
               fieldContainer[FIELD_NAME_EMAIL] &&
               fieldContainer.shopId &&
               fieldContainer[FIELD_NAME_PRIVACY_POLICY];
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const inputElements = document.querySelectorAll('input, select');
        let allFieldsValid = true;

        inputElements.forEach((inputElement) => {
            const name = inputElement.name;
            const isValid = fieldValidity[name] && fieldContainer[name] !== undefined && fieldContainer[name] !== '';

            if (!isValid) {
                inputElement.classList.add('border_red');
                allFieldsValid = false;
            } else {
                inputElement.classList.remove('border_red');
            }
        });

        if (!allFieldsValid) {
            console.log('Please fill in all required fields correctly.');
            return;
        }

        const user: User = {
            first_name: fieldContainer[FIELD_NAME_FIRST_NAME] || '',
            last_name: fieldContainer[FIELD_NAME_LAST_NAME] || '',
            email: fieldContainer[FIELD_NAME_EMAIL] || '',
            shop_id: fieldContainer.shopId || null,
            privacy_policy: fieldContainer[FIELD_NAME_PRIVACY_POLICY] ? 1 : 0,
        };

        setIsLoading(true); // Start spinner before making API call
        try {
            const result = await APIClient.Register(user);
            setResponse(result);
            localStorage.setItem("registration_data", JSON.stringify(result.data));
            console.log('Registration successful');
            navigate(ROUTE_RULES_PAGE, { replace: true });
        } catch (err) {
            const errorMessage = err.response?.data?.message || 'Registration failed. Please try again.';
            setError(errorMessage);
            console.error('Registration error:', err);
        } finally {
            setIsLoading(false); // Stop spinner after API call completes
        }
    };

    return (
        <>
            <section className="registration-page">
                <div className="container mobile_container">
                    <div className="inner-mobile-container">
                    <div className="blank_div"></div>
                        <div className="mobile_cont_section regist-cont">
                            <div className="middle_contant_main">
                                <form className="form-tag middel -section" onSubmit={handleSubmit}>
                                    <div className="input -field-div">
                                        <input onChange={onChange} name={FIELD_NAME_FIRST_NAME} type="text" placeholder="Naam*" className={`reg_input ${fieldValidity[FIELD_NAME_FIRST_NAME] === false ? 'border_red' : fieldValidity[FIELD_NAME_FIRST_NAME] === true ? 'border_green' : ''}`} onBlur={validateField} />
                                        <input onChange={onChange} name={FIELD_NAME_LAST_NAME} type="text" placeholder="Voornaam*" className={`reg_input ${fieldValidity[FIELD_NAME_LAST_NAME] === false ? 'border_red' : fieldValidity[FIELD_NAME_LAST_NAME] === true ? 'border_green' : ''}`} onBlur={validateField} />
                                        <input onChange={onChange} name={FIELD_NAME_EMAIL} type="email" placeholder="Email*" className={`reg_input ${fieldValidity[FIELD_NAME_EMAIL] === false ? 'border_red' : fieldValidity[FIELD_NAME_EMAIL] === true ? 'border_green' : ''}`} onBlur={validateField} />

                                        <select className="cust_sel sel_bg_arr w-select" onChange={onChange} name="shopId" required>
                                            <option value="" disabled selected>Select the shop</option>
                                            {shops.length === 0 ? (
                                                <option disabled>No shops available</option>
                                            ) : (
                                                shops.map(shop => (
                                                    <option key={shop.id} value={shop.id}>
                                                        {shop.name}
                                                    </option>
                                                ))
                                            )}
                                        </select>

                                        <div className="chek_bg">
                                            <div className="chek_div">
                                                <input name={FIELD_NAME_PRIVACY_POLICY} onChange={onChange} type="checkbox" defaultChecked={false} checked={fieldContainer[FIELD_NAME_PRIVACY_POLICY] as boolean} />
                                                <span className="checkmark"></span>
                                                <label className="chek_txt">
                                                    Ik ga akkoord met de
                                                    <a href="#" onClick={() => setIsModalOpen(true)}> algemene voorwaarden </a> en wens op de hoogte
                                                    te blijven van wedstrijden, diensten, promoties, … van
                                                    Jumbo*
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-btn-div">
                                        <MainButton type="submit" className="ga_ver_btn" isLoading={isLoading} disabled={!isFormValid()}>START HET SPEL</MainButton>
                                    </div>
                                    {error && <div className="error-message">{error}</div>}
                                    {response && <div className="success-message">Registration successful: {JSON.stringify(response)}</div>}
                                </form>
                            </div>
                        </div>
                        <BottomContent />
                    </div>
                </div>
            </section>

            <div className={`modal modal_main_sect fade ${isModalOpen ? 'show' : ''}`}
                tabIndex="-1"
                role="dialog"
                style={{ display: isModalOpen ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered modal_width">
                    <div className="modal-content modal_main_bg">
                        <div className="pop_modal_inn ">
                            <div className="modal-body">
                                <div className="mod_body_inn">
                                    <p className="pop_model_txt">
                                        Als verwerkingsverantwoordelijke mag Jumbo België mijn persoonsgegevens
                                        (de gegevens in dit formulier en de context waarin ze werden verzameld) opslaan in zijn bestanden
                                        met de bedoeling de communicatie te personaliseren op basis van mijn interesses (die worden afgeleid
                                        uit de evenementen en de wedstrijden waaraan ik heb deelgenomen) en telefonisch en via digitale
                                        kanalen (e-mail en/of sms) contact met me opnemen. Ik weet dat ik het recht heb om mijn toestemming
                                        op elk moment in te trekken en dat mijn gegevens worden verwerkt in overeenstemming met het
                                        privacybeleid van Jumbo, dat mij informeert over hoe ik mijn privacyinstellingen kan aanpassen
                                        en hoe ik mijn toestemming kan intrekken. <br /><br /> Indien ik geen toestemming geef worden
                                        mijn gegevens niet langer dan 3 maanden bewaard en uitsluitend gebruikt om mijn kortingen/promo
                                        opnieuw naar mij te versturen indien er iets misgelopen is bij de eerste verzending.
                                    </p>
                                    <MainButton onClick={() => setIsModalOpen(false)}>SLUITEN</MainButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegistrationPage;
