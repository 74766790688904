import App from './App';
import reactDom from 'react-dom/client';
import {StrictMode} from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import {APIClient} from "./api/APIClient";
export default App;

APIClient.init()

if (document.getElementById('root')) {
    const Index = reactDom.createRoot(document.getElementById("root"));

    Index.render(
        // <StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        // </StrictMode>
    )
}
