import React from "react";
import { Spinner } from "./Spinner/Spinner";
const MainButton=({ onClick, children, className, isLoading = false}) => {
    return (
        <div className="submit-btn">
            <button onClick={onClick} className={`ga_ver_btn ${className}`}>
                {children}{isLoading && <Spinner />}
            </button>
        </div>
    );
}
export default MainButton;
