import React from "react";
const BottomContent = () => {
    return (
        <>
            <footer className="footer_cont_main w-100 position-relative">
                <div className="footer_cont_col_bg">
                    <div className="vtm_logo"><img src="/assets/images/VTM.png" className="img-fluid vtm-logo" /></div>
                    <div className="footer_jumbo_logo"><a href="#"><img src="/assets/images/Jumbo_logo.png" className="img-fluid jumbo-logo" /></a></div>
                    <div className="vtmgo_jumbo_logo"><img src="/assets/images/VTGO.png" className="img-fluid vtmgo-img" />
                        <div className="i_button_bg_1"><a href="/assets/pdf/Wedstrijdreglement.pdf" target="_blank" className="i_btn_white">i</a></div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default BottomContent;
