// @ts-ignore
export const API_URL = import.meta.env.VITE_API_URL;
// @ts-ignore
export const GUESTCAMP_APP_BASE_URL = import.meta.env.VITE_GUESTCAMP_APP_BASE_URL;
// @ts-ignore
export const GUESTCAMP_APP_API_KEY = import.meta.env.VITE_GUESTCAMP_APP_API_KEY;
// @ts-ignore
export const GUESTCAMP_APP_CLIENT_NAME = import.meta.env.VITE_GUESTCAMP_APP_CLIENT_NAME;
// @ts-ignore
export const GUESTCAMP_APP_EVENT_NAME = import.meta.env.VITE_GUESTCAMP_APP_EVENT_NAME;

// Route Constants...
export const ROUTE_ROOT_PAGE = "/";
export const ROUTE_REGISTRATION = "/registration";
export const ROUTE_RULES_PAGE= "/rules";
export const ROUTE_MAP_PAGE = "/map";
export const ROUTE_THANKS_PAGE = "/thanks";


// field type constatnt

export const FIELD_TYPE_CHECKBOX = "checkbox";
export const FIELD_TYPE_TEXT = "text";


export const FIELD_NAME_FIRST_NAME = "first_name";
export const FIELD_NAME_LAST_NAME = "last_name";
export const FIELD_NAME_EMAIL = "email";
export const FIELD_NAME_PRIVACY_POLICY = "privacy_policy";


