import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import MapPage from './MapPage'
import Layout from './LayoutPage'
import RegistrationPage from './RegistrationPage.js'
import RulesPage from './RulesPage';
import { ROUTE_MAP_PAGE, ROUTE_REGISTRATION, ROUTE_ROOT_PAGE, ROUTE_RULES_PAGE, ROUTE_THANKS_PAGE } from '../helper/Contstant';
import IntroPage from './IntroPage';
import ThanksPage from './ThanksPage';
import {Map} from "../components/Map";


function PageRouter() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path={ROUTE_ROOT_PAGE} element={<Layout />}>
        <Route index element={<IntroPage/>} />
        <Route path={ROUTE_REGISTRATION} element={<RegistrationPage />} />
        <Route path={ROUTE_RULES_PAGE} element={<RulesPage />} />
        <Route path={ROUTE_MAP_PAGE} element={<MapPage />} />
          {/* <Route path={ROUTE_MAP_PAGE} element={<Map />} /> */}
          <Route path={ROUTE_THANKS_PAGE} element={<ThanksPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
  )
}

export default PageRouter
