import React from 'react';
import {Spinner} from "./Spinner/Spinner";

// export const CorrectLocation = ({ onClick, className="default"}) => {
//     // console.log("button call:", {
//     //     lat: {lat},
//     //     lng: {lng}
//     // });

//     return (
//         <div className="correct-location corr_bt_bg">
//             <button className={`correct ${className}`} onClick={onClick}>Correct</button>
//         </div>
//     );
// };



export const ConfirmLocation = ({ onClick, className="default", isLoading = false}) => {
    // console.log("button call:", {
    //     lat: {lat},
    //     lng: {lng}
    // });

    return (
        <div className="correct-location corr_bt_bg">
            <button className={`correct ${className}`} onClick={onClick}>CORRECT {isLoading && <Spinner />}</button>
        </div>
    );
};

