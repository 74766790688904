import React, { useEffect, useState } from "react";
import { APIClient } from "../api/APIClient";
import { User } from "../redux/models/User";
import CorrectAnswer from './CorrectAnswer';
import CountDown from "./CountDown";
import AlreadyPlayed from "./AlreadyPlayed";
import { LocalStorage } from "../helper/LocalStorage";

export default function Score() {
    const [totalScore, setTotalScore] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    const [id, setId] = useState(null);
    const [scoreData, setScoreData] = useState(null);
    // <AlreadyPlayed nextWeek={nextWeek} />
    // console.log(scoreData)
    useEffect(() => {
        // const data = localStorage.getItem('registration_data');
        // if (data) {
        //     const parsedData = JSON.parse(data);
        //     setId(parsedData.id); // Set user_id
        // }
        setId(LocalStorage.getUser().id);
        const fetchScore = async () => {
            if (id) {
                try {
                    const user: User = { id };
                    const response = await APIClient.GetScore(user);
                    const fetchedScoreData = response.data;
                    setScoreData(fetchedScoreData);

                    if (fetchedScoreData['game-details']) {
                        const totalPoints = fetchedScoreData['game-details'].total_points.replace(/,/g, '').replace('.', '.');
                        setTotalScore(parseFloat(totalPoints));
                    } else {
                        console.log("total_points not found in scoreData.");
                    }
                } catch (err) {
                    setError(err);
                    console.error("Error fetching score:", err);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchScore();
    }, [id]);

    const handleShowCorrectAnswer = () => {
        setShowCorrectAnswer(true);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error fetching score: {error.message}</div>;
    }

    const nextWeek = scoreData?.["game-details"]?.nextWeek || "";
    if (showCorrectAnswer) {
        return <CorrectAnswer scoreData={scoreData} nextWeek={nextWeek} />; // Pass scoreData as a prop
    }

    return (
        <>
            <div className="mobile_cont_section score-page">
                <h1 className="main_top_hd">JE BENT EEN ECHTE SPEURNEUS!</h1>
                <div className="middle_contant_main">
                    <div className="midd_cont_inn">
                        <div className="home_cont">
                            <ul>
                                <li>Wat een geweldige zoektocht, je hebt volgende score behaald:</li>
                            </ul>
                            <div className="point_bg">
                                <h3 className="score">{totalScore !== null ? totalScore.toFixed(2) : '0'}</h3>
                                <p className="point-txt">punten</p>
                            </div>
                            <button onClick={handleShowCorrectAnswer} className="point_loc_btn">
                                TOON DE CORRECTE LOCATIES
                            </button>
                            <div className="succes">

                                <p className='score-title'> Gewonnen? We contacteren je zelf!</p>

                              <p className='wh_color score-cont'>MAAK JE KLAAR VOOR JE VOLGENDE ZOEKTOCHT!! </p>

                            </div>
                            <div className="timer-div">
                                    <img className="time-arrow" src="/assets/images/right-arrow.svg" alt=""/>
                                    {/* <p className="time-txt">1dag - 14uren - 25minuten</p> */}
                                    <CountDown targetDate={nextWeek} />
                                    <img className="time-arrow" src="/assets/images/left-arrow.svg" alt=""/>
                                </div>
                        </div>
                        <div className="app_ico padd_top_0">
                            <img src="/assets/images/icon_app.svg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
