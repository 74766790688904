import MainButton from './MainButton'
import { ROUTE_MAP_PAGE, ROUTE_REGISTRATION } from '../helper/Contstant';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

export default function SplashPage() {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const data = localStorage.getItem('registration_data')
        setData(data);
    }, []);

    const handleNavigation = () => {
        setIsLoading(true); // Start loading
        setTimeout(() => {
            if (data) {
                navigate(ROUTE_MAP_PAGE);
            } else {
                navigate(ROUTE_REGISTRATION);
            }
            setIsLoading(false); // Stop loading after navigation
        }, 1000); // Simulate a delay for the loader (optional)
    };

    return (
        <>
            <div className="blank_div"></div>
            <div className="mobile_cont_section middel-section">
                <h1 className="main_top_hd">WELKOM BIJ <br /> BESTEMMING JUMBO</h1>
                <div className="middle_contant_main middel-sec ">
                    <div className="midd_cont_inn">
                        <div className="home_cont">
                            <ul>
                                <li>Als echte speurneus zoek je de juiste oorsprong van een aantal producten.</li>
                                <div className="hom_mid_txt">Ben je er klaar voor? Klik op<br /> “GA VERDER”</div>
                            </ul>
                            <div className="succes">SUCCES!</div>
                        </div>
                        <div className="app_ico"><img src="/assets/images/icon_app.svg" alt="" /></div>
                    </div>
                </div>
            </div>
            <MainButton onClick={handleNavigation} className="ga_ver_btn" isLoading={isLoading}>GA VERDER</MainButton>
        </>
    )
}
