import React, {useCallback, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import MainButton from "../components/MainButton";
import Mapimgproduct from "../components/Mapimgproduct";
import {APIClient, StartGameType} from "../api/APIClient";
import { ROUTE_THANKS_PAGE } from "../helper/Contstant.js";
import {MapComponent, ProductWrapper} from "../components/Map";
import {Product} from "../redux/models/Product";
import {Shop} from "../redux/models/Shop";
import {debounce} from "lodash";
import {Spinner} from "../components/Spinner/Spinner";
import {ProductResponse} from "../redux/models/ProductResponse";
import {ConfirmLocation} from "../components/Correctlocation";
import Distance from "../components/Distance.js";
import { LocalStorage } from "../helper/LocalStorage.js";

function MapPage() {
    const mapRef = useRef(null);
    const navigate = useNavigate();
    const [pageData, setPageData] = useState<ProductResponse | null | undefined>(null)
    const [products, setProducts] = useState<Product[]>([]);
    const [store, setStore] = useState<Shop>(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [scoreIds, setScoreIds] = useState({});
    const [shopCoordinates, setShopCoordinates] = useState({ lat: 0, lng: 0 });
    const [shopId, setShopId] = useState(null);
    const [isSearching, setSearching] = useState(false);
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const [suggestions, setSuggestions] = useState([]);
    const [isGameActive, setIsGameActive] = useState(false);
    const [isGameEnd, setIsGameEnd] = useState(false);
    const [isLat, setIsLat] = useState(null);
    const [isLng, setIsLng] = useState(null);
    const [selectedProductData, setSelectedProductData] = useState(null);
    const [isRefreshedProduct, setIsRefreshedProduct] =useState(false);
    const [showCorrectButton, setShowCorrectButton] = useState(false);
    const [isProductsLoading, setIsProductsLoading] = useState(false);
    const [isEndGameLoading, setIsEndGameLoading] = useState(false);
    const [showConfirmLocation, setShowConfirmLocation] = useState(false);
    const [distance, setDistance] = useState(null);
    const [showDistance, setShowDistance] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(true);

    useEffect(() => {
        const userData = LocalStorage.getUser();
        if (userData) {
            setShopCoordinates({
                lat: parseFloat(userData.shop_lat),
                lng: parseFloat(userData.shop_lng),
            });
        }
    }, []);

    useEffect(() => {
        const data = localStorage.getItem("registration_data");
        if (data && mapRef.current) {
            setIsProductsLoading(true);
            const parsedData = JSON.parse(data);
            APIClient.GetProducts({ id: parsedData.id })
                .then((response) => {
                    setIsProductsLoading(false);
                    if (response.success) {
                        setPageData(response.data)
                        // }
                    } else {
                        console.error("Failed to fetch products:", response.message);
                    }
                    setIsRefreshedProduct(false);
                })
                .catch((error) => {
                    console.error("Error fetching products:", error);
                    setIsProductsLoading(false);
                });
        }
    }, []);

    useEffect(() => {
        if (pageData){
            setProducts(pageData.products);
            const _store = pageData.store
            setStore(_store)
            if (shopCoordinates) {
                mapRef.current.setMapCenter(shopCoordinates.lat, shopCoordinates.lng);
            } else {
                mapRef.current.setMapCenter(parseFloat(_store.latitude!), parseFloat(_store.longitude!));
            }
        }
    }, [pageData, shopCoordinates]);

    const handleGameEnd = useCallback(() => {
            const productData = mapRef.current.getSelectedProduct()
            endgame(productData);
            setIsButtonVisible(true);
            setTimeout(() => {
                setIsButtonVisible(false);
            }, 1000);
        }
        , [mapRef]);

    const areAllProductsDisabled = () => {
        return products.every(product => product.active_status);
    };


    // Fetch products on mount


    const handleProductClick = (product: Product) => {
        setIsButtonVisible(true);
        console.info("handleProductClick")
        if (mapRef.current && !mapRef.current.getSelectedProduct()) {
            setSelectedProductId(product.id);
            startGame(product, (response: {score_id: number}) => {
                let _product = {...product}
                _product.score_id = response.score_id;
                mapRef.current.addMarker(_product);
            });
        }
    };

    // Start a game
    const startGame = (product, callback: (res: StartGameType) => void) => {
        console.info("startGame")
        const data = localStorage.getItem("registration_data");
        const parsed = JSON.parse(data);
        APIClient.StartGame({ user_id: parsed.id, product_id:  product.id})
            .then((response) => {
                console.info("startGame finished")
                if (response.success) {
                    callback(response.data)
                } else {
                    console.error("Failed to start game:", response.message);
                }
            })
            .catch((error) => {
                console.info("startGame finished")
                console.error("Error starting game:", error);
            });
    };


    // Handle drop
//   const handleDrop = (e) => {
//     e.preventDefault();
    const endgame = (productData: ProductWrapper) => {
        // console.log("Endgame function called. isGameEnd:", isGameEnd);
        // console.log("Making API call to end game");
        setIsEndGameLoading(true);
        const _product = productData.product
        const selectedLocation = productData.selectedLocation;
        if (_product && _product.score_id) {
            APIClient.EndGame({score_id: _product.score_id,
                 latitude:   selectedLocation.lat,
                  longitude:  selectedLocation.lng})
                .then((response) => {
                    // console.log("API response:", response);
                    if (response.success) {
                        // setDistance(response.data);
                        mapRef.current.dropMarker()
                        setProducts(prevState => {
                            return prevState.map(p => {
                                if (p.id === response.data.product_id) {
                                    return {
                                        ...p,
                                        active_status: true
                                    };
                                }
                                return p;
                            })
                        })
                        setIsEndGameLoading(false);
                        if (response.data.is_correct_drop_status) {
                            setShowConfirmLocation(true);
                            setTimeout(() => {
                                setShowConfirmLocation(false);
                            }, 3000);
                        } else {
                            setDistance(response.data.distance);
                            setShowDistance(true);
                            setTimeout(() => {
                                setShowDistance(false);
                            }, 3000);
                        }
                    } else {
                        console.error("Failed to end game:", response.message);
                        setIsEndGameLoading(false);
                    }
                })
                .catch((error) => {
                    console.error("Error ending game:", error);
                    setIsEndGameLoading(false);
                });
        }
    };

    // Fetch suggestions for the search bar
    const fetchSuggestions = (query, callback) => {
        fetch(
            `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(query)}&format=json&addressdetails=1`
        )
            .then((response) => response.json())
            .then((data) => {
                callback && callback(data)
            })
            .catch((error) => console.error("Error fetching suggestions:", error));
    };
    const debouncedSearch = debounce((query) => {
        if (query.trim()) {
            fetchSuggestions(query, (data) => {
                setSuggestions(data || [])
                setSearching(false);
            });
        } else {
            setSuggestions([]);
            setSearching(false);
        }
    }, 1000);

    React.useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);
    // Handle search input change
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearching(true)
        debouncedSearch(query);
    };

    // Handle suggestion click
    const handleSuggestionClick = (suggestion) => {
        if (mapRef.current) {
            mapRef.current.setMapCenter(parseFloat(suggestion.lat), parseFloat(suggestion.lon));
        }
        searchInputRef.current.value = "";
        setSuggestions([]);
    };


    const MapComp = React.useMemo(() => {
        // console.log("MapComponent Rendered!");
        // window.alert("MapComponent Rendered");
        return <MapComponent ref={mapRef} onDragEnd={(product) => {
            if (product){
                setShowCorrectButton(true)
            }
        }} onDragStart={(product) => {
            if (product){
                setShowCorrectButton(false)
            }
        }}/>;
    });
    if (isProductsLoading){
        return  <>
            <Spinner />
        </>
    }
    return (
        <section className="main_bg _section main_bg_map">
            <div className="map_section">
                <div className="mobile_cont_map">
                    <div
                        className="map_cont"
                        id="googleMap"
                        onDragOver={(e) => e.preventDefault()}
                    >
                        {MapComp}
                    </div>
                    <div className="mobile_cont _section_2">
                        <div className="foot_map_col">
                            <div className="foot_pro_list">
                                <ul>
                                    {products.map((product) => (
                                        <li key={product.id} className="ico_li">
                                            <div
                                                className={`product_img_div ${
                                                    product.active_status ? "disable" : ""
                                                } ${!product.active_status && selectedProductId === product.id ? "border_green" : ""}`}
                                                onClick={() => !isGameActive && !product.active_status && handleProductClick(product)}
                                            >
                                                <img className="img-fluid" src={product.image} alt={product.title} />
                                            </div>
                                            <div className={`product_name_div ${product.active_status ? "disable" : ""}`}>
                                                {product.title}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="select_bott_txt_2">SELECTEER EEN PRODUCT OM TE PLAATSEN</div>
                        </div>
                    </div>
                    <footer className="footer_cont_main">
                        <div className="search_sect_main">
                            {areAllProductsDisabled() ? (
                                <MainButton className={'bev_locat'} onClick={() => navigate(ROUTE_THANKS_PAGE)}>
                                    BEKIJK JE SCORE
                                </MainButton>
                            ) : (
                                isButtonVisible && showCorrectButton && (
                                    <MainButton
                                        isLoading={isEndGameLoading}
                                        onClick={handleGameEnd}
                                        className={'bev_locat'}
                                    >
                                        BEVESTIG LOCATIE
                                    </MainButton>
                                )
                            )}
                            {!areAllProductsDisabled() && (
                                <div className="top_search">
                                    {suggestions.length > 0 && (
                                        <ul className="suggestions-list">
                                            {suggestions.map((suggestion) => (
                                                <li key={suggestion.place_id} onClick={() => handleSuggestionClick(suggestion)}>
                                                    {suggestion.display_name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    <input
                                        ref={searchInputRef}
                                        type="text"
                                        onChange={handleSearchChange}
                                        placeholder="Zoek de locatie op de kaart"
                                        className="map_input search_icon search-city"

                                    />
                                    {
                                        isSearching && <div style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", right: "4%"}}><Spinner color={"var(--primary-color)"}/></div>
                                    }
                                </div>
                            )}
                            <div className="i_button_bg"><a href="/assets/pdf/Wedstrijdreglement.pdf" target="_blank" className="i_btn_orange">i</a></div>
                        </div>
                    </footer>
                </div>
            </div>
            {showConfirmLocation || showDistance ? (
                <div className="overlay" onClick={(e) => e.preventDefault()}></div>
            ) : null}
            {showConfirmLocation && <ConfirmLocation />}
            {showDistance && <Distance distance={distance} />}
        </section>
    );
}

export default MapPage;
