import AlreadyPlayed from '../components/AlreadyPlayed';
import BottomContent from '../components/BottomContent';
import React from 'react';
import Score from '../components/Score';
const ThanksPage = () => {
    return (
    <>
        <section className="registration-page">
            <div className="container mobile_container">
                <div className='intro-inner-sec inner-mobile-container thanks-inner-sec'>
                <div className="blank_div"></div>
                    {/* <AlreadyPlayed /> */}
                    <Score />
                    <BottomContent />
                </div>
            </div>
        </section>
    </>
  )
}
export default ThanksPage;
