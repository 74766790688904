import React from "react";
import CountDown from "./CountDown";

const AlreadyPlayed = ({ nextWeek }) => {
    const data = localStorage.getItem('registration_data');
    var firstname;
    if (data) {
        try {
            const parsedData = JSON.parse(data);
            if (parsedData && typeof parsedData === 'object') {
                firstname = parsedData.first_name; // user first-name.
            } else {
                console.log("Parsed data is not an object.");
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    } else {
        console.log("No registration data found.");
    }


    return(
        <>
            <div className="middle_contant_main mobile_cont_section already-play-page">
                <h1 className="main_top_hd">LOCATIES BEVESTIGD</h1>
                <div className="midd_cont_inn">
                    <div className="home_cont">
                        <ul>
                            <li>Hey {firstname}, je locaties van deze week werden al bevestigd. De winnaars worden persoonlijk gecontacteerd.</li>
                        </ul>
                        <div className="all_play_succes">
                            <p className='point-head primary-text'>MAAK JE KLAAR VOOR JE <br /> VOLGENDE ZOEKTOCHT!! </p>
                        </div>
                        <div className="timer-div">
                            <img className="time-arrow" src="/assets/images/right-arrow.svg" alt=""/>
                            {/* <p className="time-txt">1dag - 14uren - 25minuten</p> */}
                            <CountDown targetDate={nextWeek} />
                            <img className="time-arrow" src="/assets/images/left-arrow.svg" alt=""/>
                        </div>
                    </div>
                    <div className="app_ico"><img src="/assets/images/icon_app.svg" alt="" /></div>
                </div>
            </div>
        </>
    );
};
export default AlreadyPlayed;
