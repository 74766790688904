function Distance({ distance }) {
  return (
    <>
      <div className="distance_bg">
        <a href="#" className="distan_btn">Oei, je zat er <span className="km_color"> { distance } </span> naast. Dat was een flinke gok!</a>
      </div>
    </>
  )
}

export default Distance;
