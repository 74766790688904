// @ts-ignore
import { configureStore } from '@reduxjs/toolkit'
import appReducer from '../slices/appSlice';

import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

export const store = configureStore({
    reducer: {
        appReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
type AppDispatchFunc = () => AppDispatch
export const useAppDispatch: AppDispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

